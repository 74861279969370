.ansLBtn {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1.2rem;
    box-sizing: border-box;
    background-color: white;
    border: 1px solid #d0d0d0;
    border-radius: 6px;
    font-size: 23px;
    width: 75%;
    box-shadow: 3px 3px 3px rgb(63, 63, 63);
    font-family: 'KCC-Ganpan';

    //모바일 사이즈
    @media (max-width: 768px) {
        font-size: 16px;
        width: 100%;
        padding: 0px;
        margin: 0px;
        height: 33vh;
        border: none;
        border-radius: 0px;
        //왼쪽 아래 오른쪽 아래 둥글게
        border-bottom-left-radius: 6px;
    }
}
.ansRBtn {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1.2rem;
    box-sizing: border-box;
    background-color: white;
    border: 1px solid #d0d0d0;
    border-radius: 6px;
    font-size: 23px;
    width: 75%;
    box-shadow: 3px 3px 3px rgb(63, 63, 63);
    font-family: 'KCC-Ganpan';
    @media (max-width: 768px) {
        font-size: 16px;
        width: 100%;
        padding: 0px;
        margin: 0px;
        height: 33vh;
        border: none;
        border-radius: 0px;
        background-color: #d0d0d0;
        //왼쪽 아래 오른쪽 아래 둥글게
        border-bottom-right-radius: 6px;
    }
}
.ansLBtn:active {
    color: #fff;
    background-color: #61a7ed;
    // transform: scale(0.95);
    transition: 100ms;
    border: none;
}
.ansRBtn:active {
    color: #fff;
    background-color: #61a7ed;
    // transform: scale(0.95);
    transition: 100ms;
    border: none;
}

@font-face {
    font-family: 'KCC-Ganpan';
    src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_2302@1.0/KCC-Ganpan.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
}
