.Layout {
    width: 100%;
    font-size: 14px;
    display: flex;
    justify-content: center;
    background-color: rgba(82, 88, 136, 1);
    flex-direction: column;
    align-items: center;
}
.resultLayout {
    display: flex;
    flex-direction: column;
    background-color: #eee;
    width: 100%;
    max-width: 900px;
    align-items: center;
    margin-top: 25px;
    border-radius: 10px;
}
.resultTitle {
    width: 100%;
    text-align: center;
    padding: 15px;
    box-sizing: border-box;
    font-weight: 700;
    font-size: 25px;
    font-family: 'TTWanjudaedunsancheB';
}
.resultShow {
    border: 2px solid black;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 700px;
    border-radius: 10px;
    margin-bottom: 40px;
    background-color: white;
}
.description {
    font-family: 'KCC-Ganpan';
    font-size: 23px;
    margin-top: -5px;
}
.exp {
    margin: 25px 20px 0px 10px;
    list-style-type: square;
}
.img {
    margin-top: -5px;
    filter: drop-shadow(3px 3px 3px black);
    width: 350px;
    height: 300px;
}
.travelTitle {
    border-top: 2px solid black;
    font-family: 'TTWanjudaedunsancheB';
    letter-spacing: 3px;
    display: flex;
    align-items: flex-end;
}
.recommend1 {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
}
.jobN {
    font-family: 'KCC-Ganpan';
    font-size: 35px;
    margin-top: -20px;
    border: 4px outset rgb(52, 177, 255);
    width: 80%;
    display: flex;
    align-items: center;
    height: 50px;
    justify-content: center;
}
.travelN {
    font-family: 'KCC-Ganpan';
    font-size: 35px;
    margin-top: -20px;
    border: 4px outset rgb(36, 216, 123);
    width: 60%;
    display: flex;
    align-items: center;
    height: 50px;
    justify-content: center;
}
.YoutubeN {
    font-family: 'KCC-Ganpan';
    font-size: 35px;
    margin-top: -20px;
    border: 4px outset rgb(250, 26, 26);
    width: 60%;
    display: flex;
    align-items: center;
    height: 50px;
    justify-content: center;
}
.mbti {
    font-family: 'KCC-Ganpan';
    font-size: 40px;
    margin-top: 13px;
}
.jobImg {
    width: 350px;
    height: 300px;
    filter: drop-shadow(3px 3px 3px black);
    margin-top: -30px;
}
.rec_img {
    width: 350px;
    height: 250px;
    filter: drop-shadow(3px 3px 3px black);
    margin-bottom: 35px;
}
.titleImg {
    width: 40px;
    height: 40px;
}
.title1 {
    display: flex;
    align-items: flex-end;
    justify-content: center;
}
.rImg {
    width: 30px;
    height: 30px;
    margin-top: 10px;
}
.btn {
    font-size: 22px;
    border-radius: 20px;
    width: 165px;
    height: 47px;
    margin-bottom: 20px;
    font-family: 'ImcreSoojin';
    cursor: pointer;
    box-shadow: 3px 3px 3px rgb(66, 66, 66);
    margin-left: 30px;
}
.share {
    background-color: #ff9ce9;
}
.review {
    background-color: #f5e16b;
}
.chat {
    background-color: #4b8cf4;
}
.share:active {
    background-color: #ffe5f9;
    box-shadow: none;
}
.review:active {
    background-color: #fffbd8;
    box-shadow: none;
}
.chat:active {
    background-color: #d8edff;
    box-shadow: none;
}
.buttonBox {
    display: flex;
    margin-bottom: 20px;
    width: 100%;
}
@font-face {
    font-family: 'KCC-Ganpan';
    src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_2302@1.0/KCC-Ganpan.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'ImcreSoojin';
    src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_20-04@2.3/ImcreSoojin.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'TTWanjudaedunsancheB';
    src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_2304-2@1.0/TTWanjudaedunsancheB.woff2')
        format('woff2');
    font-weight: 700;
    font-style: normal;
}
@media screen {
    @media (max-width: 768px) {
        .resultLayout {
            width: 360px;
        }
        .resultShow {
            width: 330px;
        }
        .resultTitle {
            font-size: 21px;
        }
        .resultMain {
            font-size: 30px;
        }
        .img {
            width: 250px;
            height: 200px;
        }
        .travelTitle {
            font-size: 22px;
        }
        iframe {
            width: 320px;
            height: 200px;
        }
        .btn {
            width: 105px;
            height: 52px;
            font-size: 18px;
            margin-left: 10px;
        }
        .exp {
            font-size: 18px;
        }
        .jobImg {
            width: 165px;
            height: 150px;
        }
        .rec_img {
            width: 165px;
            height: 150px;
        }
        .jobN {
            font-size: 30px;
            width: 75%;
        }
        .YoutubeN {
            font-size: 30px;
            width: 63%;
        }
        .travelN {
            font-size: 30px;
            width: 75%;
        }
    }
}
