/* CreateReview.css */

.popup-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5); /* 배경을 약간 흐릿하게 표시 */
    z-index: 9999;
}

.popup-content {
    width: 400px;
    background-color: #fff;
    padding: 10px;
    border-radius: 8px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
}

.popup-content h3 {
    font-size: 18px;
}

.popup-content form {
    display: flex;
    flex-direction: column;
}

.popup-content input,
.popup-content textarea {
    max-width: 380px;
    margin-bottom: 10px;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.popup-content button {
    padding: 8px 16px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.popup-content button:hover {
    background-color: #0056b3;
}

.close-button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    font-size: 18px;
    color: #999;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    border-radius: 50%;
}

.close-button:hover {
    color: #333;
    background-color: #eee;
}

.popup-nav {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}
