//footer페이지 Css
#main-footer {
    text-align: center;
    background: #525888;
    color: #fff;
    padding: 10px 0px 10px 0px;
    margin: 0px;
    //모바일 사이즈
    @media (max-width: 768px) {
        padding: 20px 0px 10px 0px;
    }
}
.row {
    text-align: center;
    margin: 10px 0px;
}
.ul-container {
    display: flex;
    list-style: none;
    padding: 0px;
    margin: 0px;
    flex-wrap: wrap;
    justify-content: center;
    //모바일 사이즈
    @media (max-width: 768px) {
        flex-wrap: nowrap;
    }
}
.footer-li {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 35px;
    width: 200px;
    color: rgba(255, 255, 255, 0.5);
    div {
        color: rgba(255, 255, 255, 0.5);
    }
    div:hover {
        color: rgba(255, 255, 255, 1);
        //올렸을때 마우스 포인터 보이게
        cursor: pointer;
    }
}
html {
    background-color: rgba(82, 88, 136, 1);
    height: 100vh;
}

form .loginForm {
    display: flex;
    align-items: center;
}
.adfit {
    margin: 10px;
    padding: 10px;
    //모바일 사이즈
    @media (max-width: 768px) {
        display: none;
    }
}
.mobile_adfit {
    margin: 10px;
    padding: 10px;
    //웹 사이즈
    @media (min-width: 768px) {
        display: none;
    }
}
.loginForm {
    display: flex;
    align-items: center;
}
.container {
    height: 100vh;
    width: 100%;

    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    //모바일 사이즈
    @media (max-width: 768px) {
        display: block;
    }
}
.logoContainer {
    position: absolute;
    top: 0;
    left: 0;
    //모바일 사이즈
    @media (max-width: 768px) {
        background-color: rgba(255, 255, 255, 1);
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        position: relative;
        height: 10vh;
        justify-content: flex-start;
        //top left 수치 0말고 아예 없게 지정안된상태로 ?
    }
}
//로그인 페이지 css
.Left {
    background-color: rgba(255, 255, 255, 1);
    height: 100vh;
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: rgba(82, 88, 136, 1);

    //모바일 사이즈
    @media (max-width: 768px) {
        background-color: white;
        width: 100%;
        height: 43.25vh;
    }
}

.Right {
    background-color: rgba(82, 88, 136, 1);
    height: 100vh;
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: rgba(255, 255, 255, 1);
    //모바일 사이즈
    @media (max-width: 768px) {
        width: 100%;
        height: 49.25vh;
    }
}
//로그인 페이지 css
.contentSignup {
    color: rgba(255, 255, 255, 1);
    font-size: 1.5rem;
    font-weight: 600;
    //모바일 사이즈
    @media (max-width: 768px) {
        max-width: 410px;
        font-size: 1.2rem;
        font-weight: 400;
    }
}

.signInForm {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
}

//메인 페이지 문구 크기 css
.hero__text {
    font-weight: 600;
    //모바일 사이즈
    @media (max-width: 768px) {
        font-size: 1rem;
        font-weight: 400;
    }
}
