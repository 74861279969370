#main-footer {
    width: 100%;
}
@font-face {
    font-family: 'KCC-Ganpan';
    src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_2302@1.0/KCC-Ganpan.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
}

.mainLayout {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    background-color: rgba(82, 88, 136, 1);
}
.questionLayout {
    display: flex;
    flex-direction: column;
    background-color: rgba(82, 88, 136, 1);
    width: 100%;
    align-items: center;
    border-radius: 10px;
    height: 60vh;
    margin: 40px 0px;
    padding: 40px 0px;
}
.typequest_1 {
    background-color: #eee;
    min-width: 900px;
    max-width: 900px;
    border-radius: 10px;
    height: 70vh;

    h1 {
        margin: 30px;
        text-align: center;
        margin: 20px 5px 0px 5px;
    }
    /* 모바일 사이즈 .css */
    @media (max-width: 768px) {
        min-width: 350px;
        max-width: 350px;
        height: 50vh;
        h1 {
            margin-top: 20px;
            text-align: center;
            margin: 20px 5px 0px 5px;
        }
    }
}
.mbtiTitle {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    padding: 15px;
    box-sizing: border-box;
    font-weight: 700;
    font-size: 25px;
    font-family: 'KCC-Ganpan';
    //아랫줄 색
    border-bottom: 1px solid #d0d0d0;
    //모바일 사이즈
    @media (max-width: 768px) {
        padding: 10px;
        font-size: 20px;
        height: 5vh;
    }
}
.mbtiTitle div:nth-child(1) {
    display: flex;
    flex: 1;
}
.questionItemLayout {
    display: flex;
    flex: 1;
    margin: 10px 15px 5px 15px;
}
.questionBox {
    padding: 20px;
    margin-top: 6vh;
    margin-bottom: 6vh;
    //모바일 사이즈
    @media (max-width: 768px) {
        h1 {
            font-size: 20px;
        }
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 45vh;
        margin: 0px;
        padding: 0px;
    }
}
.questionTitle {
    font-family: 'KCC-Ganpan';
    font-weight: 300;

    //모바일 사이즈
    @media (max-width: 768px) {
        h1 {
            font-size: 20px;
        }
    }
}
.answerItemLayout {
    width: 100%;
    padding-bottom: 10px;
    display: flex;
    flex-direction: column;
}
.mbti_counter {
    font-weight: bold;
}

.answerLayout {
    margin-top: 50px;
    display: flex;
    justify-content: center;
    align-items: center;

    //모바일 사이즈
    @media (max-width: 768px) {
        flex-direction: row;
        justify-content: space-around;
    }
}
.leftBox {
    //가로정렬
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    @media (max-width: 768px) {
        border-top: 1px solid #d0d0d0;
        width: 50%;
    }
}
.rightBox {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    @media (max-width: 768px) {
        border-top: 1px solid #d0d0d0;
        width: 50%;
    }
}
.buttonText {
    margin: 10px;
}